import { DateTime } from 'luxon'
import * as Yup from 'yup'
import { productTypes } from './HDDLine/constants'

type Entity = {
  startDate: string
  type: string
  quantity: string
  restlessNight?: boolean
}

export type HddForm = {
  id?: string
  startDate: string | null
  endDate: string | null
  restlessNight?: boolean
  type: string
  quantity: string
  key: string
  isEditing: boolean
}

Yup.addMethod(
  Yup.object,
  'uniqueTypeAndDay',
  function (
    mapper: (a: {
      [hdds: string]: Entity[]
    }) => { type: string; date: string }[]
  ) {
    return this.test(
      'uniqueTypeAndDay',
      function (obj: { [hdds: string]: Entity[] } | undefined) {
        if (!obj) {
          return
        }
        const keys = mapper(obj)
        const dayAndProductToIndexMap = getDayAndProductToIndexMap(keys)
        let errors: Yup.ValidationError[] = []

        for (const dIdx of Object.values(dayAndProductToIndexMap)) {
          const productsForDate = Object.values(dIdx).flat()

          if (productsForDate.length > 2) {
            errors = errors.concat(
              productsForDate.map(idx =>
                this.createError({
                  path: `hdds[${idx}].dateOccurence`,
                  message:
                    'Au plus deux interventions peuvent être réalisées par jour.'
                })
              )
            )
          }

          for (const pIdxes of Object.values(dIdx)) {
            if (pIdxes.length > 1) {
              errors = errors.concat(
                pIdxes.map(idx =>
                  this.createError({
                    path: `hdds[${idx}].uniqueness`,
                    message:
                      "Le même type d'intervention ne peut être sélectionné qu'une fois par jour."
                  })
                )
              )
            }
          }
        }

        return errors.length ? new Yup.ValidationError(errors) : true
      }
    )
  }
)

export const hddsFormValidationSchema = () =>
  Yup.object()
    .shape({
      hdds: Yup.array()
        .ensure()
        .of(
          Yup.object().shape({
            startDate: Yup.string()
              .required('Indiquer une date.')
              .test('is-date', 'Indiquer une date.', date => {
                return !!(date && DateTime.fromISO(date).isValid)
              }),
            type: Yup.string()
              .required('Indiquer un produit.')
              .oneOf(productTypes, 'Sélectionner un produit.'),
            quantity: Yup.number()
              .moreThan(0, 'La quantité ne peut être nulle.')
              .max(12, 'La quantité ne peut être supérieure à 12.')
              .required('Indiquer une quantité.')
          })
        )
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .uniqueTypeAndDay((a: { hdds: Entity[] }) =>
      a.hdds.map(hdd => ({
        date: hdd.startDate,
        type: hdd.type
      }))
    )

/*
 * Transforme une liste d'objets {type, date} vers un dictionnaire
 * {[date] : {[type]: number[]}} avec
 * - date : la date (yyyy MM dd);
 * - type : le type de produit JOUR / NUIT;
 * - number[] : les index de la liste ayant date et type.
 */
const getDayAndProductToIndexMap = (
  keys: { type: string; date: string }[]
): {
  [date: string]: { [type: string]: number[] }
} => {
  const dayAndProductToIndexMap: {
    [date: string]: { [type: string]: number[] }
  } = {}

  for (let i = 0; i < keys.length; i++) {
    const { date, type } = keys[i]

    if (!type || !date) {
      continue
    }

    const day = DateTime.fromISO(date, { zone: 'Europe/Paris' }).toFormat(
      'yyyy MM dd'
    )
    const products = dayAndProductToIndexMap[day]
    const dayOrNight = type.split(' ')[1]

    dayAndProductToIndexMap[day] = {
      ...products,
      [dayOrNight]: [i].concat(products?.[dayOrNight] || [])
    }
  }

  return dayAndProductToIndexMap
}
