import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {
  getProductLabel,
  TARIF_NIGHT_WATCH,
  TARIF_SURCHARGE_TYPE
} from 'utils/labels'
import { FormikErrors } from 'formik'
import FormHelperText from '@mui/material/FormHelperText'
import { getErrorForField, getQuantityString } from '../utils'
import { HourDeclarationDetail } from 'adapters/backend/hourDeclaration'
import DateItem from './DateItem'

type Props = {
  detail: HourDeclarationDetail
  error: string | FormikErrors<any> | undefined
}

const EditableHDDLine = ({ detail, error }: Props) => {
  const errors = {
    uniqueness: getErrorForField(error, 'uniqueness'),
    dateOccurence: getErrorForField(error, 'dateOccurence')
  }

  return (
    <Grid
      container
      display={'flex'}
      width={'100%'}
      flexDirection={'row'}
      color={errors.uniqueness || errors.dateOccurence ? '#d44949' : undefined}
    >
      <Grid
        item
        container
        xs={3}
        textAlign="center"
        display={'flex'}
        flexDirection={'row'}
      >
        <DateItem startDate={detail.startDate} />
      </Grid>
      <Grid item xs={6} textAlign="center">
        <Box>
          {getProductLabel(
            detail.type || '',
            TARIF_SURCHARGE_TYPE.NORMAL,
            detail.restlessNight
              ? TARIF_NIGHT_WATCH.AGITEE
              : TARIF_NIGHT_WATCH.CALME
          )}
        </Box>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Box>
          {getQuantityString(
            detail.quantity || '',
            !!detail.type?.includes('HORAIRE')
          )}
        </Box>
      </Grid>
      {(errors.uniqueness || errors.dateOccurence) && (
        <FormHelperText
          sx={{ flexDirection: 'column', whiteSpace: 'pre' }}
          error={true}
        >
          {[errors.uniqueness, errors.dateOccurence].filter(Boolean).join('\n')}
        </FormHelperText>
      )}
    </Grid>
  )
}

export default EditableHDDLine
