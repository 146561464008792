import { FormikErrors } from 'formik'
import { DateTime } from 'luxon'
import { convertHoursToHoursMinutes } from 'utils/tools'

export const generateID = () => {
  return (
    Date.now().toString(36) +
    Math.random().toString(36).substring(2, 12).padStart(12, '0')
  )
}

export const getErrorForField = (
  error: string | FormikErrors<any> | undefined,
  field: string
) => {
  if (
    typeof error === 'string' ||
    error instanceof String ||
    error === undefined
  ) {
    return error as string | undefined
  }

  return error[field] as string
}

export const getQuantityString = (quantity: string, isDuration: boolean) => {
  return isDuration ? `${getTimeString(quantity)} ` : `1`
}

const getTimeString = (quantity: string) => {
  if (quantity === '') {
    return ''
  }

  const hoursMinutes = convertHoursToHoursMinutes(parseFloat(quantity))

  return `${hoursMinutes.hours}h${hoursMinutes.minutes
    .toString()
    .padStart(2, '0')}`
}

export const getFirstOfMonth = (date: string) =>
  DateTime.fromFormat(date, 'yyyy-MM', { zone: 'utc' }).startOf('month')

export const getLastOfMonth = (date: string) =>
  DateTime.fromFormat(date, 'yyyy-MM', { zone: 'utc' }).endOf('month')
