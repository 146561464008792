import {
  Grid,
  Typography,
  useMediaQuery,
  // TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem
} from '@mui/material'
import FormikTextField from './FormikTextField'

export default function BeneficiaryFields({
  formik,
  optionalFields = false,
  contactFields = false
}: any) {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <Typography
          component="h3"
          variant={isSm ? 'h6' : 'h4'}
          color="primary"
          display="inline-block"
          lineHeight="unset"
          marginLeft="5px"
          marginBottom={'15px'}
          marginTop={'15px'}
          align="left"
        >
          {formik.values.referralLink !== 'beneficiaire'
            ? formik.values.referralLink === 'other'
              ? 'Informations du bénéficiaire'
              : "Votre demande d'aide concerne"
            : 'Vos informations de contact'}
        </Typography>
        <FormikTextField
          name="firstNameBenef"
          label={
            formik.values.referralLink === 'beneficiaire'
              ? 'Prénom'
              : 'Prénom du bénéficiaire'
          }
          formik={formik}
        />
      </Grid>
      <Grid item xs={12} marginBottom={'20px'}>
        <FormikTextField
          name="lastNameBenef"
          label={
            formik.values.referralLink === 'beneficiaire'
              ? 'Nom de famille'
              : 'Nom de famille du bénéficiaire'
          }
          formik={formik}
        />
      </Grid>
      {contactFields && (
        <>
          <Grid item xs={12} marginBottom={'20px'}>
            <FormikTextField
              name="phoneBenef"
              label={
                formik.values.referralLink === 'beneficiaire'
                  ? 'Téléphone'
                  : 'Téléphone du bénéficiaire'
              }
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} marginBottom={'20px'}>
            <FormikTextField
              name="emailBenef"
              label={
                formik.values.referralLink === 'beneficiaire'
                  ? 'Email'
                  : 'Email du bénéficiaire'
              }
              formik={formik}
            />
          </Grid>
        </>
      )}
      {optionalFields && (
        <>
          {/* <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <TextField
              fullWidth
              id="birthdayBenef"
              name="birthdayBenef"
              type="date"
              label={'Date de naissance du bénéficiaire'}
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.birthdayBenef}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.birthdayBenef &&
                Boolean(formik.errors.birthdayBenef)
              }
              helperText={
                formik.touched.birthdayBenef && formik.errors.birthdayBenef
              }
            />
          </Grid> */}
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="days-of-intervention-label">
                Aide financière
              </InputLabel>
              <Select
                labelId="days-of-intervention-label"
                id="days-of-intervention"
                value={formik.values.financialAid}
                label="Aide financière"
                onChange={e => {
                  formik.setFieldValue('financialAid', e.target.value)
                }}
              >
                <MenuItem value={'APA'}>APA</MenuItem>
                <MenuItem value={'PCH, ACTP'}>PCH, ACTP</MenuItem>
                <MenuItem value={'ARDH'}>ARDH</MenuItem>
                <MenuItem value={'Mutuelle, assurance'}>Mutuelle</MenuItem>
                <MenuItem value={'Autre'}>Autre</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="days-of-intervention-label">
                Jours d'intervention
              </InputLabel>
              <Select
                multiple
                labelId="days-of-intervention-label"
                id="days-of-intervention"
                value={formik.values.daysOfIntervention}
                label="Jours d'intervention"
                onChange={e => {
                  formik.setFieldValue('daysOfIntervention', e.target.value)
                }}
              >
                <MenuItem value={'MONDAY'}>Lundi</MenuItem>
                <MenuItem value={'TUESDAY'}>Mardi</MenuItem>
                <MenuItem value={'WEDNESDAY'}>Mercredi</MenuItem>
                <MenuItem value={'THURSDAY'}>Jeudi</MenuItem>
                <MenuItem value={'FRIDAY'}>Vendredi</MenuItem>
                <MenuItem value={'SATURDAY'}>Samedi</MenuItem>
                <MenuItem value={'SUNDAY'}>Dimanche</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <TextField
              fullWidth
              id="hourVolume"
              name="hourVolume"
              type="number"
              label={"Volume d'heures"}
              value={formik.values.hourVolume}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.hourVolume && Boolean(formik.errors.hourVolume)
              }
              helperText={formik.touched.hourVolume && formik.errors.hourVolume}
            />
          </Grid> */}
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="services-label">Services</InputLabel>
              <Select
                multiple
                labelId="services-label"
                id="services"
                value={formik.values.services}
                label="Services"
                onChange={e => {
                  formik.setFieldValue('services', e.target.value)
                }}
              >
                <MenuItem value={'bed-time'}>Lever, coucher</MenuItem>
                <MenuItem value={'washing-dressing'}>
                  Toilette, habillage
                </MenuItem>
                <MenuItem value={'movement'}>Aide à la mobilité</MenuItem>
                <MenuItem value={'rappels'}>Rappels</MenuItem>
                <MenuItem value={'assistance-with-eating'}>
                  Aide aux repas
                </MenuItem>
                <MenuItem value={'cleaning'}>Ménage</MenuItem>
                <MenuItem value={'laundry-ironing'}>
                  Lessive, repassage
                </MenuItem>
                <MenuItem value={'company-stimulation'}>
                  Compagnie, stimulation
                </MenuItem>
                <MenuItem value={'night-watch'}>Surveillance de nuit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </>
  )
}
