import { usePDF } from 'react-to-pdf'
import { DateTime } from 'luxon'
import { Button, List, ListItem, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import theme from '../../theme'
import { GradientButton } from 'components/Button/Button.style'
import { Loading } from 'components/Loading/Loading'
import {
  HourDeclarationDetail,
  postValidation
} from 'adapters/backend/hourDeclaration'
import { Mission } from 'types/entity'
import { HDDetailsFooter } from './HDDetailsFooter'
import { Pricing } from 'utils/labels'
import HDDLine from './HDDLine/HDDLine'
import { hddsFormValidationSchema } from './validationSchema'
import { useFormik } from 'formik'

type Props = {
  encryptedMissionId: string
  mission: Mission
  detailsWithPrices: HourDeclarationDetail[]
  allValidated: boolean
  date: string
  onContest: () => void
}

export default function HDDProducts({
  encryptedMissionId,
  mission,
  detailsWithPrices,
  allValidated,
  date,
  onContest
}: Props) {
  const formik = useFormik<{ hdds: HourDeclarationDetail[] }>({
    initialValues: { hdds: detailsWithPrices },
    onSubmit: () => {
      return
    },
    enableReinitialize: true,
    validationSchema: hddsFormValidationSchema,
    validateOnMount: true
  })

  const pricings = detailsWithPrices.reduce<Pricing[]>(
    (acc, detail) => acc.concat(detail.prices),
    []
  )

  const { toPDF, targetRef } = usePDF({ filename: 'declaration.pdf' })

  const onValidate = () =>
    postValidation(encryptedMissionId, date).then(() => {
      window.location.href = `/confirmer-famille?missionId=${encryptedMissionId}&date=${date}`
    })

  if (!mission) {
    return <Loading />
  }

  return (
    <>
      <Box paddingBottom={2} display={'flex'} justifyContent={'flex-end'}>
        <GradientButton onClick={() => toPDF()}>
          Télécharger le PDF
        </GradientButton>
      </Box>
      <Paper
        ref={targetRef}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {detailsWithPrices.length === 0 && (
          <Box>Aucune déclaration pour le mois en cours</Box>
        )}
        {mission && (
          <Box fontSize={'1.3em'} textAlign={'center'} width="100%">
            <Box
              textAlign={'center'}
              color={theme.palette.primary.main}
              fontWeight="500"
            >
              DÉCLARATION HORAIRE{' '}
              {mission.contractType === 'liberal' ? 'LIBÉRAL' : 'CDI'} DU MOIS
              DE{' '}
              {DateTime.fromFormat(date, 'yyyy-MM')
                .setLocale('fr')
                .toFormat('MMMM yyyy')
                .toUpperCase()}
            </Box>
            <Box>
              déposée par {mission.careGiver.person.firstName}{' '}
              {mission.careGiver.person.lastName}
            </Box>
            <br />
          </Box>
        )}
        <List sx={{ width: '100%' }}>
          <ListItem
            key={'labels'}
            sx={{
              width: '100%',
              paddingLeft: 0,
              borderBottom: '1px solid lightgrey'
            }}
          >
            <Grid
              container
              display={'flex'}
              width={'100%'}
              flexDirection={'row'}
            >
              <Grid item xs={3} textAlign="center">
                <b>Date</b>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <b>Nature des interventions</b>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <b>Quantité</b>
              </Grid>
            </Grid>
          </ListItem>
          {detailsWithPrices.map((detail, idx) => (
            <ListItem
              key={detail.id}
              sx={{
                width: '100%',
                paddingLeft: 0,
                borderBottom: '1px solid lightgrey'
              }}
            >
              <HDDLine detail={detail} error={formik.errors.hdds?.[idx]} />
            </ListItem>
          ))}
        </List>
        <br />

        <HDDetailsFooter mission={mission} pricings={pricings} />
      </Paper>
      {(detailsWithPrices.length && (
        <>
          <Box
            paddingTop={2}
            display={'flex'}
            justifyContent={'flex-end'}
            width={'100%'}
            gap={2}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{
                color: 'white',
                borderRadius: '8px',
                width: 120,
                fontSize: '0.875rem'
              }}
              disabled={allValidated}
              onClick={onContest}
            >
              CONTESTER
            </Button>
            <GradientButton
              style={{ width: 120 }}
              onClick={() => onValidate()}
              disabled={!formik.isValid || allValidated}
            >
              VALIDER
            </GradientButton>
          </Box>
          {allValidated && (
            <Box
              paddingTop={2}
              display={'flex'}
              justifyContent={'flex-end'}
              width={'100%'}
              gap={2}
            >
              Vous avez déjà validé le mois en cours
            </Box>
          )}
        </>
      )) || <></>}
    </>
  )
}
