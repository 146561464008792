/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { ThemeProvider, useTheme } from '@emotion/react'
import Nav from 'components/Nav/Nav'
import Copyright from 'components/Copyright/Copyright'
import Keycloak from 'keycloak-js'
import Unauthorized from 'components/Unauthorized/Unauthorized'
import LoadingNav from 'components/LoadingNav/LoadingNav'
import { DatePicker } from '@mui/x-date-pickers'
import { getApiClient } from 'adapters/backend/network'
import { Button } from 'components/Button/Button.style'
import Title from 'components/Title/Title'
import { DateTime } from 'luxon'

type Invoice = {
  id: string
  reference: string
  type: string
  status: string
  pricings: Pricing[]
}

type Pricing = {
  careGiverHT: number
  careGiverTTC: number
  careGiverTVA: number
  feeHT: number
  feeTTC: number
  feeTVA: number
  globalFees: number
}

const zero = (nb: number): string => (nb > 9 ? String(nb) : `0${nb}`)

export default function Invoices({ keycloak }: { keycloak?: Keycloak }) {
  const { yearMonth } = useParams<{ yearMonth: string }>()
  const [errors, setErrors] = useState<string[]>([])
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [futureInvoices, setFutureInvoices] = useState<Invoice[]>([])
  const [urssafInvoices, setUrssafInvoices] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState(
    yearMonth ? new Date(yearMonth) : new Date()
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hddsToPrice, setHddsToPrice] = useState<number>(0)
  const [pricesDone, setPricesDone] = useState(false)
  const [isProcessingPricings, setIsProcessingPricings] = useState(false)
  const [pricingsMax, setPricingsMax] = useState(0)
  const [invoicesPage, setInvoicesPage] = useState(1)
  const [draftPage, setDraftPage] = useState(1)

  const pageLength = 10

  const startStatusTimeout = () => {
    setTimeout(() => {
      try {
        getApiClient()
          .get(`/invoices/pricings/status`)
          .then(({ data }) => {
            setHddsToPrice(data)

            if (data === 0) {
              setIsProcessingPricings(false)
              setPricingsMax(0)
              setPricesDone(true)
              refetch()

              return
            }

            startStatusTimeout()
          })
      } catch (e) {
        setIsProcessingPricings(false)
        setPricingsMax(0)
        setPricesDone(false)
        refetch()
      }
    }, 1000)
  }

  const startProcessingPrincings = () => {
    getApiClient()
      .get(`/invoices/pricings/delete`)
      .then(() => {
        getApiClient().get(`/invoices/pricings/generate`)

        getApiClient()
          .get(`/invoices/pricings/status`)
          .then(({ data }) => setPricingsMax(data))
          .then(() => {
            setIsProcessingPricings(true)
            startStatusTimeout()
          })
      })
  }

  const refetch = () => {
    setInvoices([])
    setFutureInvoices([])
    setIsSubmitting(false)

    getApiClient()
      .get(
        `/invoices/admin/all?dateMonth=${
          DateTime.fromISO(selectedDate.toISOString()).year
        }-${zero(DateTime.fromISO(selectedDate.toISOString()).month)}`
      )
      .then(({ data }) => setInvoices(data))

    const nextMonth = DateTime.fromISO(selectedDate.toISOString())
      .startOf('month')
      .plus({ month: 1 })

    getApiClient()
      .get(
        `/invoices/urssaf/generate?date=${zero(nextMonth.day)}${zero(
          nextMonth.month
        )}${nextMonth.year}`
      )
      .then(({ data }) => setUrssafInvoices(data))

    if (pricesDone) {
      Promise.all([
        getApiClient()
          .get(
            `/invoices/liberal/generate?date=${zero(nextMonth.day)}${zero(
              nextMonth.month
            )}${nextMonth.year}`
          )
          .then(({ data }) => data || []),
        getApiClient()
          .get(
            `/invoices/cesu/generate?date=${zero(nextMonth.day)}${zero(
              nextMonth.month
            )}${nextMonth.year}`
          )
          .then(({ data }) => data || [])
      ]).then(([liberal, cesu]) => setFutureInvoices([...liberal, ...cesu]))
    }
  }

  useEffect(() => {
    refetch()
  }, [pricesDone])

  const onGenerate = () => {
    setIsSubmitting(true)
    const nextMonth = DateTime.fromISO(selectedDate.toISOString())
      .startOf('month')
      .plus({ month: 1 })

    Promise.all([
      getApiClient()
        .get(
          `/invoices/liberal/generate?date=${zero(nextMonth.day)}${zero(
            nextMonth.month
          )}${nextMonth.year}&mode=real`
        )
        .then(({ data }) => data || []),
      getApiClient()
        .get(
          `/invoices/cesu/generate?date=${zero(nextMonth.day)}${zero(
            nextMonth.month
          )}${nextMonth.year}&mode=real`
        )
        .then(({ data }) => data || [])
    ])
      .then(() => {
        return Promise.all([
          getApiClient()
            .get(
              `/invoices/liberal/send-to-pennylane?date=${zero(
                nextMonth.day
              )}${zero(nextMonth.month)}${nextMonth.year}&mode=real`
            )
            .then(({ data }) => data || []),
          getApiClient()
            .get(
              `/invoices/cesu/send-to-pennylane?date=${zero(
                nextMonth.day
              )}${zero(nextMonth.month)}${nextMonth.year}&mode=real`
            )
            .then(({ data }) => data || [])
        ])
      })
      .then(([liberal, cesu]) => setErrors([...liberal, ...cesu]))
      .then(refetch)
      .catch(errors => {
        console.error(errors)
        setErrors([...errors, errors])
        refetch()
      })
  }

  const onUrssaf = () => {
    setIsSubmitting(true)
    const nextMonth = DateTime.fromISO(selectedDate.toISOString())
      .startOf('month')
      .plus({ month: 1 })

    getApiClient()
      .get(
        `/invoices/urssaf/generate?date=${zero(nextMonth.day)}${zero(
          nextMonth.month
        )}${nextMonth.year}&mode=real`
      )
      .then(({ data }) => data || [])
      .then(errors => setErrors(errors.split('\n')))
      .then(refetch)
      .catch(errors => {
        console.error(errors)
        setErrors([...errors, errors])
        refetch()
      })
  }

  const onDelete = (invoiceId: string) => {
    setIsSubmitting(true)
    getApiClient()
      .delete(`/invoices/${invoiceId}`)
      .then(() => refetch())
  }

  const handleInvoicesPagechange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setInvoicesPage(value)
  }

  const handleDraftPagechange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setDraftPage(value)
  }

  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (!keycloak.authenticated) {
      window.location.assign(keycloak.createLoginUrl())
    }

    refetch()
  }, [keycloak, selectedDate])

  const theme = useTheme()
  const navigate = useNavigate()

  const handleMonthSelect = (date: Date) => {
    const yearMonth = `${date.getFullYear()}-${zero(date.getUTCMonth() + 1)}`

    setSelectedDate(date)

    navigate(`/factures/${yearMonth}`)
  }

  const pageInvoices = invoices
    .slice()
    .splice(invoicesPage * pageLength, pageLength)

  const pageDrafts = futureInvoices
    .slice()
    .splice(draftPage * pageLength, pageLength)

  return (
    (keycloak?.authenticated && keycloak.hasRealmRole('edit-invoices') && (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <Nav keycloak={keycloak} />
          <Box
            component="main"
            sx={{
              backgroundColor: currentTheme =>
                currentTheme.palette.mode === 'light'
                  ? currentTheme.palette.grey[100]
                  : currentTheme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto'
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3" component="h1" sx={{ mb: 4 }}>
                  Facturation
                </Typography>
                <Grid container spacing={2} flexDirection="column">
                  <Grid item md={3}>
                    <Stack spacing={2} sx={{ mb: 4 }}>
                      <DatePicker
                        openTo="month"
                        views={['year', 'month']}
                        onChange={(date: Date | null) => {
                          handleMonthSelect(date || new Date())
                        }}
                        defaultValue={new Date(selectedDate || '')}
                      />
                      <Button
                        onClick={startProcessingPrincings}
                        style={{ float: 'right' }}
                        disabled={isProcessingPricings}
                      >
                        Générer les prix
                      </Button>
                      {isProcessingPricings && (
                        <LinearProgress
                          variant="determinate"
                          value={
                            pricingsMax !== 0
                              ? ((pricingsMax - hddsToPrice) / pricingsMax) *
                                100
                              : 0
                          }
                        />
                      )}
                    </Stack>
                  </Grid>
                  {isSubmitting && (
                    <Grid item md={1}>
                      <CircularProgress style={{ float: 'right' }} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Title>Factures du mois</Title>
                    <Grid
                      container
                      direction="column"
                      justifyContent={'space-between'}
                      height="100%"
                      width="100%"
                    >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Référence</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Statut</TableCell>
                              <TableCell>Total Intervenant TTC €</TableCell>
                              <TableCell>Total Commission HT €</TableCell>
                              <TableCell></TableCell>
                              <TableCell>id</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pageInvoices.map(invoice => (
                              <TableRow
                                key={invoice.id}
                                style={{
                                  backgroundColor: errors.find(error =>
                                    error.includes(invoice.id)
                                  )
                                    ? 'rgba(255,127,127,0.1)'
                                    : 'white'
                                }}
                              >
                                <TableCell>{invoice.reference}</TableCell>
                                <TableCell>{invoice.type}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                                <TableCell>
                                  {invoice.pricings
                                    .reduce(
                                      (acc, { careGiverTTC }) =>
                                        acc + careGiverTTC / 10000,
                                      0
                                    )
                                    .toFixed(2)}
                                </TableCell>
                                <TableCell>
                                  {invoice.pricings
                                    .reduce(
                                      (acc, { feeHT }) => acc + feeHT / 10000,
                                      0
                                    )
                                    .toFixed(2)}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    disabled={isSubmitting}
                                    color="secondary"
                                    onClick={() => onDelete(invoice.id)}
                                  >
                                    Supprimer
                                  </Button>
                                </TableCell>
                                <TableCell>{invoice.id}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <Pagination
                        count={Math.round(invoices.length / pageLength)}
                        color="primary"
                        onChange={handleInvoicesPagechange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Title>Brouillon</Title>
                    <Grid
                      container
                      direction="column"
                      justifyContent={'space-between'}
                      height="100%"
                      width="100%"
                    >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Référence</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Statut</TableCell>
                              <TableCell>Total Intervenant TTC €</TableCell>
                              <TableCell>Total Commission HT €</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pageDrafts.map(invoice => (
                              <TableRow key={invoice.id}>
                                <TableCell>{invoice.reference}</TableCell>
                                <TableCell>{invoice.type}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                                <TableCell>
                                  {invoice.pricings
                                    .reduce(
                                      (acc, { careGiverTTC }) =>
                                        acc + careGiverTTC / 10000,
                                      0
                                    )
                                    .toFixed(2)}
                                </TableCell>
                                <TableCell>
                                  {invoice.pricings
                                    .reduce(
                                      (acc, { feeHT }) => acc + feeHT / 10000,
                                      0
                                    )
                                    .toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Pagination
                      count={Math.round(futureInvoices.length / pageLength)}
                      color="primary"
                      onChange={handleDraftPagechange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={onGenerate}
                      style={{ float: 'right' }}
                      disabled={
                        isSubmitting || isProcessingPricings || !pricesDone
                      }
                    >
                      Sauvegarder ces factures
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Title>Factures éligibles au Crédit d'impôt immédiat</Title>
                  <Grid>{urssafInvoices}</Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={onUrssaf}
                    style={{ float: 'right' }}
                    disabled={isSubmitting || isProcessingPricings}
                  >
                    Envoyer à l'urssaf
                  </Button>
                </Grid>
                {errors.length > 0 && (
                  <Grid item xs={12}>
                    <Title>Erreurs</Title>
                    <Grid
                      container
                      direction="column"
                      justifyContent={'space-between'}
                      height="100%"
                      width="100%"
                    >
                      {errors.map(errorMessage => (
                        <Grid item key={errorMessage} color="salmon">
                          {errorMessage}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Paper>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    )) ||
    (keycloak?.authenticated && !keycloak.hasRealmRole('edit-invoices') ? (
      <Unauthorized />
    ) : (
      <LoadingNav keycloak={keycloak} />
    ))
  )
}
