import { addDays, startOfWeek } from 'date-fns'

import { DateTime } from 'luxon'

import { EntityType, Tarif } from 'types/entity'
import { DAY_JS, Day } from 'types/enum'
import * as Yup from 'yup'

export const DayFromStringToNumber = {
  [Day.SUNDAY]: 0,
  [Day.MONDAY]: 1,
  [Day.TUESDAY]: 2,
  [Day.WEDNESDAY]: 3,
  [Day.THURSDAY]: 4,
  [Day.FRIDAY]: 5,
  [Day.SATURDAY]: 6
}

export const DayFromNumberToString = {
  0: Day.SUNDAY,
  1: Day.MONDAY,
  2: Day.TUESDAY,
  3: Day.WEDNESDAY,
  4: Day.THURSDAY,
  5: Day.FRIDAY,
  6: Day.SATURDAY
}

const fromCtsToEuros = (amount: number) => (amount / 100).toFixed(2)

const sumTarifTotal = (tarifs: Tarif[]) =>
  tarifs.reduce<Tarif>(
    (total, tarif) => ({
      candCTaxExcludedAmount:
        total.candCTaxExcludedAmount + tarif.candCTaxExcludedAmount,
      candCVATAmount: total.candCVATAmount + tarif.candCVATAmount,
      candCVATRate: total.candCVATRate + tarif.candCVATRate,
      careGiverTaxExcludedAmount:
        total.careGiverTaxExcludedAmount + tarif.careGiverTaxExcludedAmount,
      careGiverVATAmount: total.careGiverVATAmount + tarif.careGiverVATAmount,
      careGiverVATRate: total.careGiverVATRate + tarif.careGiverVATRate,
      product: tarif.product,
      surchargeType: tarif.surchargeType,
      travelFees: tarif.travelFees,
      hoursBilled: total.hoursBilled + tarif.hoursBilled,
      globalFees: total.globalFees + tarif.globalFees
    }),
    {
      candCTaxExcludedAmount: 0,
      candCVATAmount: 0,
      candCVATRate: 0,
      careGiverTaxExcludedAmount: 0,
      careGiverVATAmount: 0,
      careGiverVATRate: 0,
      product: '',
      surchargeType: '',
      travelFees: 0,
      hoursBilled: 0,
      globalFees: 0
    }
  )

export const formatTarif = (
  tarifs: Tarif[] | undefined,
  entityType: EntityType
) => {
  if (!tarifs) {
    return ''
  }

  if (tarifs.length === 0) {
    return `Calcul...`
  }

  const total = sumTarifTotal(tarifs)

  if (entityType === 'mission') {
    return `Salaire: ${fromCtsToEuros(
      total.careGiverTaxExcludedAmount + total.careGiverVATAmount
    )} €`
  }

  return `Com.: ${fromCtsToEuros(
    total.candCVATAmount + total.candCTaxExcludedAmount
  )} € /
  Salaire: ${fromCtsToEuros(
    total.careGiverTaxExcludedAmount + total.careGiverVATAmount
  )} €`
}

export const getNextDay = (day: Day): Day => {
  const allDays = Object.values(Day)
  const index = allDays.indexOf(day)

  if (index === allDays.length - 1) {
    return allDays[0]
  }

  return allDays[index + 1]
}

// Match HH:mm format string
export const REGEX_MILITARY_TIME = /([01]?[0-9]|2[0-3]):([0-5][0-9])/

export const timeStringToUnixDate = (timeString: string): number =>
  Date.parse(`01 Jan 1970 ${timeString}:00 GMT+1`)

export const scheduleFormValidationSchema = () =>
  Yup.object().shape({
    day: Yup.string().required('Vous devez selectionner un jour dans la liste'),
    endDay: Yup.string().required(
      'Vous devez selectionner un jour dans la liste'
    ),
    contractType: Yup.string().required(
      'Vous devez selectionner un type de contrat dans la liste'
    ),
    startHour: Yup.string().test(
      // "not-required-nigth-shift",
      "Vous devez indiquer l'horaire de début",
      (value, { createError }) => {
        if (value === null || value === undefined) {
          return createError({
            message: "Vous devez indiquer l'horaire de début"
          })
        }

        if (!REGEX_MILITARY_TIME.test(value)) {
          return createError({
            message: "Mauvais format d'heure (format attendu hh:mm)"
          })
        }

        // if (parent.endHour !== '00:00' && timeStringToUnixDate(value as string) > timeStringToUnixDate(parent.endHour)) {
        //   return createError({ message: "L'heure de début ne peut pas être après l'heure de fin" });
        // }

        return true
      }
    ),
    endHour: Yup.string().test(
      // "not-required-nigth-shift",
      "Vous devez indiquer l'horaire de fin",
      (value, { createError }) => {
        if (value === null || value === undefined) {
          return createError({
            message: "Vous devez indiquer l'horaire de fin"
          })
        }

        if (!REGEX_MILITARY_TIME.test(value)) {
          return createError({
            message: "Mauvais format d'heure (format attendu hh:mm)"
          })
        }

        // if (value !== '00:00' && timeStringToUnixDate(value as string) < timeStringToUnixDate(parent.startHour)) {
        //   return createError({ message: "L'heure de fin ne peut pas être avant l'heure de début" });
        // }

        return true
      }
    )
  })

export const isHourNightshift = ({
  startHour,
  endHour
}: {
  startHour: string
  endHour: string
}) =>
  endHour !== '00:00' &&
  timeStringToUnixDate(startHour as string) > timeStringToUnixDate(endHour)

export const extractDayFromUTCDate = (date: Date): Day => {
  const dayJs = date.getUTCDay()

  return DAY_JS[dayJs]
}

export const zeroNumber = (n: number): string => {
  if (n < 10) {
    return `0${n}`
  }

  return String(n)
}

export const formatDateInHourUTC = (date: string): string => {
  const dateJS = new Date(date)

  return `${zeroNumber(dateJS.getUTCHours())}:${zeroNumber(
    dateJS.getUTCMinutes()
  )}`
}

export function applyHourToDate(date: Date, hour: string): Date {
  const [hours, minutes] = hour.split(':').map(Number)

  date.setHours(hours)
  date.setMinutes(minutes)

  return date
}

export const mergeHourAndDate = (hour: string, date: string): string =>
  date + 'T' + hour + ':OO'

export const getNextDayAfterDate = (
  date: Date,
  targetDayOfweek: number
): Date => {
  const currentDate = new Date(date)
  const currentDay = currentDate.getDay()
  const daysToAdd = targetDayOfweek - currentDay + 7
  const nextDate = addDays(startOfWeek(currentDate), daysToAdd)

  return nextDate
}

export const getFrDayOfweek = (dayOfWeek: number): string => {
  const daysOfWeek = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi'
  ]

  return daysOfWeek[dayOfWeek]
}

export function minusWeeksEuropeTz(date: Date, weeksToMinus: number): Date {
  const utcDate = date && DateTime.fromISO(date.toISOString(), { zone: 'utc' })
  const parisDate = utcDate.setZone('Europe/Paris', { keepLocalTime: true })
  let resultDate = parisDate.minus({ weeks: weeksToMinus })

  resultDate = resultDate.setZone('utc', { keepLocalTime: true })

  return resultDate.toJSDate()
}

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const convertHoursToHoursMinutes = (
  hours: number
): { hours: number; minutes: number } => {
  const wholeHours = Math.floor(hours)
  const fractionalPart = hours - wholeHours
  const minutes = Math.round(fractionalPart * 60)

  return { hours: wholeHours, minutes }
}
