import { getApiClient } from 'adapters/backend/network'
import { Mission } from 'types/entity'
import { Pricing } from 'utils/labels'

export type HourDeclarationDetail = {
  id?: string
  prices: Pricing[]
  startDate: string
  endDate: string
  restlessNight?: boolean
  type?: string
  quantity?: string
}

export const getHDs = async (
  missionId: string,
  date: string
): Promise<{
  mission: Mission
  detailsWithPrices: HourDeclarationDetail[]
  allValidated: boolean
}> => {
  return getApiClient()
    .get<{
      mission: Mission
      detailsWithPrices: HourDeclarationDetail[]
      allValidated: boolean
    }>(`/hourDeclaration/get-mission/${missionId}/${date}`)
    .then(({ data }) => data)
}

export const postValidation = async (
  missionId: string,
  date: string
): Promise<void> => {
  await getApiClient()
    .post<void>(`/hourDeclaration/validate-all-family/${missionId}/${date}`)
    .catch(() => ({}))
}

export type Correction = {
  id?: string
  startDate: string
  endDate: string
  type: string
  quantity: string
  restlessNight?: boolean
}

export const postCorrection = async (
  missionId: string,
  date: string,
  corrections: Correction[]
): Promise<void> => {
  await getApiClient()
    .post<void>(`/hourDeclaration/correction/${missionId}/${date}`, {
      hourDeclarationDetailsDto: corrections
    })
    .catch(() => ({}))
}

export const postConflict = async (missionId: string): Promise<void> => {
  await getApiClient()
    .post<void>(`/hourDeclaration/conflict-all-family/${missionId}`)
    .catch(() => ({}))
}
