import { useFormik } from 'formik'
import { DateTime } from 'luxon'
import { Button, List, ListItem, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import theme from '../../theme'
import { GradientButton } from 'components/Button/Button.style'
import EditableHDDLine from 'components/HDDetails/HDDLine/EditableHDDLine'
import {
  HddForm,
  hddsFormValidationSchema
} from 'components/HDDetails/validationSchema'
import {
  Correction,
  HourDeclarationDetail,
  postCorrection
} from 'adapters/backend/hourDeclaration'
import { Mission } from 'types/entity'
import { generateID } from './utils'

export type EditHddPayload = {
  startDate?: string
  endDate?: string
  type?: string
  quantity?: string
  restlessNight?: boolean
  isEditing?: boolean
}

type Props = {
  encryptedMissionId: string
  mission: Mission
  detailsWithPrices: HourDeclarationDetail[]
  allValidated: boolean
  date: string
}

export default function EditableHDDProducts({
  encryptedMissionId,
  mission,
  detailsWithPrices,
  allValidated,
  date
}: Props) {
  const formik = useFormik<{ hdds: HddForm[] }>({
    initialValues: {
      hdds: detailsWithPrices.map(hdd => ({
        ...hdd,
        key: hdd.id || generateID(),
        isEditing: false,
        type: hdd.type || '',
        quantity: hdd.quantity || ''
      }))
    },
    onSubmit: () => {
      return
    },
    enableReinitialize: true,
    validationSchema: hddsFormValidationSchema,
    validateOnMount: true
  })

  const onEditHdd = async (idx: number, value: EditHddPayload) => {
    const newHdds = [...formik.values.hdds]

    newHdds[idx] = {
      ...newHdds[idx],
      ...value
    }
    await formik.setFieldValue('hdds', newHdds, true)
  }

  const onDelete = (idx: number) => {
    const newHdds = [...formik.values.hdds]

    newHdds.splice(idx, 1)
    formik.setFieldValue('hdds', newHdds, true)
  }

  const onValidate = () => {
    const payload = formik.values.hdds.map(
      hdd =>
        ({
          id: hdd.id,
          startDate: hdd.startDate,
          endDate: hdd.startDate,
          type: hdd.type,
          quantity: hdd.quantity,
          restlessNight: hdd.restlessNight
        } as Correction)
    )

    postCorrection(encryptedMissionId, date, payload).then(() => {
      window.location.href = `/confirmer-famille?missionId=${encryptedMissionId}&date=${date}`
    })
  }

  const onAddHdd = () =>
    formik.setFieldValue(
      'hdds',
      formik.values.hdds.concat([
        {
          startDate: null,
          endDate: null,
          type: '',
          quantity: '',
          key: generateID(),
          isEditing: true
        }
      ]),
      true
    )

  const onConfirmLine = async (idx: number) => {
    const hdds = [...formik.values.hdds]

    hdds[idx].isEditing = false
    const editingHdds = hdds.filter(hdd => hdd.isEditing)
    const confirmedHdds = hdds.filter(hdd => !hdd.isEditing)

    await formik.setFieldValue(
      'hdds',
      [...confirmedHdds]
        .sort(
          (a, b) =>
            new Date(a.startDate as string).getTime() -
            new Date(b.startDate as string).getTime()
        )
        .concat(editingHdds),
      true
    )
  }

  return (
    <>
      <Box paddingBottom={2} display={'flex'} justifyContent={'flex-end'}></Box>
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {formik.values.hdds.length === 0 && (
          <Box>Aucune déclaration pour le mois en cours</Box>
        )}

        <Box fontSize={'1.3em'} textAlign={'center'} width="100%">
          <Box
            textAlign={'center'}
            color={theme.palette.primary.main}
            fontWeight="500"
          >
            DÉCLARATION HORAIRE{' '}
            {mission.contractType === 'liberal' ? 'LIBÉRAL' : 'CDI'} DU MOIS DE{' '}
            {DateTime.fromFormat(date, 'yyyy-MM')
              .setLocale('fr')
              .toFormat('MMMM yyyy')
              .toUpperCase()}
          </Box>
          <Box>
            déposée par {mission.careGiver.person.firstName}{' '}
            {mission.careGiver.person.lastName}
          </Box>
          <br />
        </Box>
        <List sx={{ width: '100%' }}>
          <ListItem
            key={'labels'}
            sx={{
              width: '100%',
              paddingLeft: 0,
              borderBottom: '1px solid lightgrey'
            }}
          >
            <Grid
              container
              display={'flex'}
              width={'100%'}
              flexDirection={'row'}
            >
              <Grid item xs={2} textAlign="center">
                <b>Date</b>
              </Grid>
              <Grid item xs={5} textAlign="center">
                <b>Nature des interventions</b>
              </Grid>
              <Grid item xs={2} textAlign="center">
                <b>Quantité</b>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <b>Actions</b>
              </Grid>
            </Grid>
          </ListItem>
          {formik.values.hdds.map((hdd, idx) => (
            <ListItem
              key={hdd.key}
              sx={{
                width: '100%',
                paddingLeft: 0,
                borderBottom: '1px solid lightgrey'
              }}
            >
              <EditableHDDLine
                detail={hdd}
                onDelete={() => onDelete(idx)}
                onChange={args => onEditHdd(idx, args)}
                error={formik.errors.hdds?.[idx]}
                onConfirm={() => onConfirmLine(idx)}
                date={date}
              />
            </ListItem>
          ))}
        </List>
        <Button
          startIcon={<AddIcon />}
          type="button"
          variant="outlined"
          onClick={onAddHdd}
        >
          Ajouter une intervention
        </Button>
        <br />
      </Paper>
      {formik.values.hdds.length ? (
        <>
          <Box
            paddingTop={2}
            display={'flex'}
            justifyContent={'flex-end'}
            width={'100%'}
            gap={2}
          >
            <GradientButton
              onClick={() => onValidate()}
              disabled={!formik.isValid || allValidated}
            >
              VALIDER LA DÉCLARATION
            </GradientButton>
          </Box>
          {allValidated && (
            <Box
              paddingTop={2}
              display={'flex'}
              justifyContent={'flex-end'}
              width={'100%'}
              gap={2}
            >
              Vous avez déjà validé le mois en cours
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
