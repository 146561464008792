import {
  getProductLabel,
  TARIF_NIGHT_WATCH,
  TARIF_SURCHARGE_TYPE
} from 'utils/labels'

export const productTypes = [
  'HORAIRE JOUR',
  'FORFAIT JOUR',
  'HORAIRE NUIT',
  'FORFAIT NUIT'
]

export enum OptionValues {
  HoraireJour,
  ForfaitJour,
  HoraireNuit,
  ForfaitNuitCalme,
  ForfaitNuitAgitee
}

export const getOptionValue = (
  type: string,
  restlessNight?: boolean
): OptionValues | string => {
  if (type === 'HORAIRE JOUR') {
    return OptionValues.HoraireJour
  }
  if (type === 'FORFAIT JOUR') {
    return OptionValues.ForfaitJour
  }
  if (type === 'HORAIRE NUIT') {
    return OptionValues.HoraireNuit
  }
  if (type === 'FORFAIT NUIT' && !restlessNight) {
    return OptionValues.ForfaitNuitCalme
  }
  if (type === 'FORFAIT NUIT' && restlessNight) {
    return OptionValues.ForfaitNuitAgitee
  }

  return ''
}

export const productSelectOptions = [
  {
    value: OptionValues.HoraireJour,
    label: getProductLabel('HORAIRE JOUR', TARIF_SURCHARGE_TYPE.NORMAL)
  },
  {
    value: OptionValues.ForfaitJour,
    label: getProductLabel('FORFAIT JOUR', TARIF_SURCHARGE_TYPE.NORMAL)
  },
  {
    value: OptionValues.HoraireNuit,
    label: getProductLabel('HORAIRE NUIT', TARIF_SURCHARGE_TYPE.NORMAL)
  },
  {
    value: OptionValues.ForfaitNuitCalme,
    label: getProductLabel(
      'FORFAIT NUIT',
      TARIF_SURCHARGE_TYPE.NORMAL,
      TARIF_NIGHT_WATCH.CALME
    )
  },
  {
    value: OptionValues.ForfaitNuitAgitee,
    label: getProductLabel(
      'FORFAIT NUIT',
      TARIF_SURCHARGE_TYPE.NORMAL,
      TARIF_NIGHT_WATCH.AGITEE
    )
  }
]

export const OptionValuesToValueMap: Record<
  OptionValues,
  { type: string; restlessNight?: boolean }
> = {
  [OptionValues.HoraireJour]: { type: 'HORAIRE JOUR' },
  [OptionValues.ForfaitJour]: { type: 'FORFAIT JOUR' },
  [OptionValues.HoraireNuit]: { type: 'HORAIRE NUIT' },
  [OptionValues.ForfaitNuitCalme]: {
    type: 'FORFAIT NUIT',
    restlessNight: false
  },
  [OptionValues.ForfaitNuitAgitee]: {
    type: 'FORFAIT NUIT',
    restlessNight: true
  }
}
