import { capitalize } from './tools'

export type Pricing = {
  careGiverTaxExcludedAmount: number
  careGiverVATRate: number
  careGiverVATAmount: number
  candCTaxExcludedAmount: number
  candCVATAmount: number
  candCVATRate: number
  hoursBilled: number
  globalFees: number
  product: string
  productType: PRODUCT_TYPE
  surchargeType: TARIF_SURCHARGE_TYPE
  travelFees: number
  rulesName: string
  description: string
  slotStartDate: string
  slotEndDate: string
  nightType: TARIF_NIGHT_WATCH
  customCesuTTC: number | null
  contractType: string
}

export enum PRODUCT_TYPE {
  HORAIRE = 'HORAIRE',
  FORFAIT = 'FORFAIT',
  PASS = 'PASS',
  COMMISSION = 'COMMISSION',
  TRAVEL_FEES = 'TRAVEL_FEES',
  AVOIR = 'AVOIR',
  DEFAULT = 'DEFAULT'
}

export enum TARIF_SURCHARGE_TYPE {
  NORMAL = 'NORMAL',
  MAJOREE = 'MAJORE',
  DOUBLE = 'DOUBLE'
}

export enum TARIF_NIGHT_WATCH {
  CALME = 'CALME',
  AGITEE = 'AGITEE'
}

const getProductSurchargeSuffix = (surcharge: TARIF_SURCHARGE_TYPE) => {
  switch (surcharge) {
    case TARIF_SURCHARGE_TYPE.NORMAL:
      return ''
    case TARIF_SURCHARGE_TYPE.MAJOREE:
      return 'Majoré'
    case TARIF_SURCHARGE_TYPE.DOUBLE:
      return '1er mai'
  }
}

export const getProductLabel = (
  label: string,
  surcharge: TARIF_SURCHARGE_TYPE,
  nightType?: TARIF_NIGHT_WATCH
) => {
  const nightTypeLabel =
    nightType && nightType === TARIF_NIGHT_WATCH.AGITEE ? 'agitée' : 'calme'

  if (label === 'HORAIRE JOUR') {
    const suffix = getProductSurchargeSuffix(surcharge)

    return `Heure de jour ${suffix ? suffix : '(08h-20h)'}`.trim()
  }

  if (label === 'HORAIRE NUIT') {
    return `Heure de nuit (20h-08h)`.trim()
  }

  if (label === 'FORFAIT JOUR') {
    const suffix = getProductSurchargeSuffix(surcharge)

    return `Forfait Jour 12h ${suffix ? suffix : '(08h-20h)'}`.trim()
  }

  if (label === 'FORFAIT NUIT') {
    return `Garde de nuit ${nightTypeLabel}`.trim()
  }

  if (label === 'PASS 24H') {
    const suffix = getProductSurchargeSuffix(surcharge)

    return `Pass 24h (nuit ${nightTypeLabel} + jour) ${suffix}`.trim()
  }

  return (capitalize(label) + ' ' + getProductSurchargeSuffix(surcharge)).trim()
}
