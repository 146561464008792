import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

type Props = {
  error?: string | undefined
  isDuration: boolean
  value: string
  setValue: (newValue: string) => void
}

const minTime = DateTime.fromSeconds(0, { zone: 'utc' })
const maxTime = DateTime.fromSeconds(12 * 60 * 60, { zone: 'utc' })

const QuantityInput = ({ error, isDuration, value, setValue }: Props) => {
  const duration = DateTime.fromSeconds(parseFloat(value) * 60 * 60, {
    zone: 'utc'
  })

  const onChange = (val: DateTime | null) => {
    if (!val) {
      return
    }

    setValue((val.hour + val.minute / 60).toFixed(2))
  }

  return isDuration ? (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="UTC">
      <TimePicker
        ampm={false}
        slotProps={{
          textField: {
            size: 'small',
            placeholder: 'HH:MM',
            error: !!error,
            helperText: error,
            FormHelperTextProps: { sx: { margin: 0 } },
            sx: { width: '80%' }
          }
        }}
        timezone="UTC"
        value={duration}
        onChange={onChange}
        minTime={minTime}
        maxTime={maxTime}
        timeSteps={{ minutes: 15 }}
        skipDisabled
      />
    </LocalizationProvider>
  ) : (
    <TextField
      size="small"
      type="number"
      inputProps={{ min: 0, style: { textAlign: 'center' } }}
      FormHelperTextProps={{ sx: { margin: 0 } }}
      value={value}
      onChange={evt => setValue(evt.target.value)}
      disabled={!isDuration}
      error={!!error}
      helperText={error}
    />
  )
}

export default QuantityInput
