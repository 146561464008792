import { useEffect, useState } from 'react'
import Dashboard from './pages/Dashboard/Dashboard'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './pages/Home/Home'
import Keycloak from 'keycloak-js'
import CareGiver from './components/CareGiver/CareGiver'
import Contact from './pages/Contact/Contact'
import Quote from './pages/Quote/Quote'
import TSForm from 'pages/TSForm/TSForm'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import UrssafForm from 'pages/UrssafForm/UrssafForm'
import ScheduleEditor from 'pages/ScheduleEditor/ScheduleEditor'
import { NotFound } from 'components/NotFound/NotFound'
import ConfirmHD from 'pages/Landings/ConfirmHD'
import ConflictHD from 'pages/Landings/ConflictHD'
import FamilyHDDetails from 'pages/Landings/FamilyHDDetails'
import Invoices from 'pages/Invoices/Invoices'

export default function App() {
  const [keycloak, setKeycloak] = useState<Keycloak>()
  const { search } = useLocation()
  const apikey = new URLSearchParams(search).get('apikey')

  useEffect(() => {
    try {
      const initKeyCloak = new Keycloak({
        realm: process.env.REACT_APP_AUTH_REALM || '',
        url: process.env.REACT_APP_AUTH_URL || '',
        clientId: process.env.REACT_APP_AUTH_CLIENT || ''
      })

      initKeyCloak.onTokenExpired = () => {
        initKeyCloak.updateToken(60 * 60 * 24 * 30).then(refreshed => {
          if (refreshed) {
            initKeyCloak?.token &&
              localStorage.setItem('access_token', initKeyCloak?.token)
            initKeyCloak?.refreshToken &&
              localStorage.setItem('refresh_token', initKeyCloak?.refreshToken)
          }
        })
      }

      initKeyCloak.init({ checkLoginIframe: false }).then(() => {
        setKeycloak(initKeyCloak)
      })
    } catch (error) {
      console.error('Failed to initialize adapter:', error)
    }
  }, [])

  useEffect(() => {
    if (!keycloak) {
      return
    }

    if (keycloak.authenticated) {
      keycloak?.token && localStorage.setItem('access_token', keycloak?.token)
      keycloak?.refreshToken &&
        localStorage.setItem('refresh_token', keycloak?.refreshToken)
    }

    return
  }, [keycloak])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <div className="container">
        <Routes>
          <Route path="/contact" element={<Contact />} />
          <Route path="/devis" element={<Quote keycloak={keycloak} />} />
          <Route
            path="/devis/:leadId/edit/:quoteId"
            element={<Quote keycloak={keycloak} />}
          />
          <Route
            path="/devis/:leadId"
            element={<Quote keycloak={keycloak} />}
          />
          <Route
            path="/contact-travailleur-social"
            element={<TSForm keycloak={keycloak} />}
          />
          <Route path="/" element={<Home keycloak={keycloak} />} />
          <Route path="/staffing" element={<Dashboard keycloak={keycloak} />} />
          <Route
            path="/intervenant"
            element={<CareGiver keycloak={keycloak} />}
          />
          <Route path="/urssaf" element={<UrssafForm keycloak={keycloak} />} />
          <Route
            path="/schedules/:entityType/:entityId"
            element={apikey ? <ScheduleEditor /> : <NotFound />}
          />
          <Route
            path="/details-declaration-famille"
            element={<FamilyHDDetails />}
          />
          <Route path="/confirmer-famille" element={<ConfirmHD />} />
          <Route path="/litige-famille" element={<ConflictHD />} />
          <Route path="/factures" element={<Invoices keycloak={keycloak} />} />
          <Route
            path="/factures/:yearMonth"
            element={<Invoices keycloak={keycloak} />}
          />
        </Routes>
      </div>
    </LocalizationProvider>
  )
}
