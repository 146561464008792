import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { DateTime } from 'luxon'
import {
  getProductLabel,
  TARIF_NIGHT_WATCH,
  TARIF_SURCHARGE_TYPE
} from 'utils/labels'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ClearIcon from '@mui/icons-material/Clear'
import Select from '@mui/material/Select'
import {
  getOptionValue,
  OptionValues,
  OptionValuesToValueMap,
  productSelectOptions
} from './constants'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { FormikErrors } from 'formik'
import FormHelperText from '@mui/material/FormHelperText'
import QuantityInput from './QuantityInput'
import CheckIcon from '@mui/icons-material/Check'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { EditHddPayload } from '../EditableHDDProducts'
import {
  getErrorForField,
  getFirstOfMonth,
  getLastOfMonth,
  getQuantityString
} from '../utils'
import { HddForm } from '../validationSchema'
import DateItem from './DateItem'

type Props = {
  date: string
  detail: HddForm
  error: string | FormikErrors<any> | undefined
  onDelete: () => void
  onChange: (value: EditHddPayload) => Promise<void>
  onConfirm: () => Promise<void>
}

const EditableHDDLine = ({
  date,
  detail,
  error,
  onDelete,
  onChange,
  onConfirm
}: Props) => {
  const errors = {
    date: getErrorForField(error, 'startDate'),
    type: getErrorForField(error, 'type'),
    quantity: getErrorForField(error, 'quantity'),
    uniqueness: getErrorForField(error, 'uniqueness'),
    dateOccurence: getErrorForField(error, 'dateOccurence')
  }

  const minDate = getFirstOfMonth(date)
  const maxDate = getLastOfMonth(date)

  const onChangeDate = async (date: DateTime | null) => {
    if (!date) {
      return
    }

    onChange({
      startDate: date.toISO() as string,
      endDate: date.toISO() as string
    })
  }

  const onChangeProductType = (type: string) => {
    const value = OptionValuesToValueMap[type as unknown as OptionValues]

    if (value.type.includes('FORFAIT')) {
      onChange({
        type: value.type,
        restlessNight: value.restlessNight,
        quantity: '1'
      })
    } else {
      onChange({
        type: value.type,
        restlessNight: value.restlessNight
      })
    }
  }

  const onChangeQuantity = (quantity: string) => {
    onChange({
      quantity
    })
  }

  const toggleIsEditing = async (val: boolean) => onChange({ isEditing: val })

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="UTC">
      <Grid
        container
        display={'flex'}
        width={'100%'}
        flexDirection={'row'}
        color={
          errors.uniqueness || errors.dateOccurence ? '#d44949' : undefined
        }
      >
        <Grid
          item
          container
          xs={2}
          textAlign="center"
          display={'flex'}
          flexDirection={'row'}
        >
          {detail.isEditing ? (
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              timezone="UTC"
              slotProps={{
                textField: {
                  size: 'small',
                  placeholder: 'Jour JJ/MM',
                  error: !!errors.date,
                  helperText: errors.date,
                  FormHelperTextProps: { sx: { margin: 0 } }
                }
              }}
              format="EEE dd/MM"
              value={
                detail.startDate
                  ? DateTime.fromISO(detail.startDate, { zone: 'utc' })
                  : null
              }
              onAccept={onChangeDate}
            />
          ) : (
            <DateItem startDate={detail.startDate || ''} />
          )}
        </Grid>
        <Grid item xs={5} textAlign="center">
          {detail.isEditing ? (
            <>
              <Select
                size="small"
                placeholder="Nature de l'intervention"
                value={
                  getOptionValue(detail.type, detail.restlessNight) as string
                }
                onChange={evt => onChangeProductType(evt.target.value)}
                sx={{ width: '80%' }}
                error={!!errors.type}
              >
                {productSelectOptions.map(option => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.type && (
                <FormHelperText
                  sx={{ width: '80%', justifySelf: 'center' }}
                  error
                >
                  {errors.type}
                </FormHelperText>
              )}
            </>
          ) : (
            <Box>
              {getProductLabel(
                detail.type,
                TARIF_SURCHARGE_TYPE.NORMAL,
                detail.restlessNight
                  ? TARIF_NIGHT_WATCH.AGITEE
                  : TARIF_NIGHT_WATCH.CALME
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={2} textAlign="center">
          {detail.isEditing ? (
            <QuantityInput
              isDuration={!detail.type.includes('FORFAIT')}
              value={detail.quantity}
              setValue={onChangeQuantity}
              error={errors.quantity}
            />
          ) : (
            <Box>
              {getQuantityString(
                detail.quantity,
                detail.type?.includes('HORAIRE')
              )}
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={3}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={2}
        >
          {detail.isEditing ? (
            <Button
              onClick={onConfirm}
              type="button"
              variant="text"
              startIcon={<CheckIcon />}
              size="small"
              sx={{
                textDecoration: 'underline',
                '& .MuiButton-icon': { marginRight: '2px' }
              }}
              disabled={!!error}
            >
              Confirmer
            </Button>
          ) : (
            <Button
              onClick={() => toggleIsEditing(true)}
              type="button"
              variant="text"
              startIcon={<ModeEditIcon />}
              size="small"
              sx={{
                textDecoration: 'underline',
                '& .MuiButton-icon': { marginRight: '2px' }
              }}
            >
              Modifier
            </Button>
          )}
          <Button
            onClick={onDelete}
            type="button"
            variant="text"
            color="error"
            startIcon={<ClearIcon />}
            size="small"
            sx={{
              textDecoration: 'underline',
              '& .MuiButton-icon': { marginRight: '2px' }
            }}
          >
            Supprimer
          </Button>
        </Grid>
        {detail.isEditing && (errors.uniqueness || errors.dateOccurence) && (
          <FormHelperText
            sx={{ flexDirection: 'column', whiteSpace: 'pre' }}
            error={true}
          >
            {[errors.uniqueness, errors.dateOccurence]
              .filter(Boolean)
              .join('\n')}
          </FormHelperText>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default EditableHDDLine
