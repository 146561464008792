import Grid from '@mui/material/Grid'
import { DateTime } from 'luxon'
import { capitalize } from 'utils/tools'

const DateItem = ({ startDate }: { startDate: string }) => (
  <>
    <Grid xs={6} textAlign="left" item>
      {capitalize(
        DateTime.fromISO(startDate || '')
          .setLocale('fr')
          .toFormat('EEEE')
      )}
    </Grid>
    <Grid xs={6} textAlign="right" item>
      {capitalize(
        DateTime.fromISO(startDate || '')
          .setLocale('fr')
          .toFormat('dd/MM')
      )}
    </Grid>
  </>
)

export default DateItem
