import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getHDs, HourDeclarationDetail } from 'adapters/backend/hourDeclaration'
import LegacyFamilyHDDetails from 'components/HDDetails/Legacy/LegacyFamilyHDDetails'
import { NotFound } from 'components/NotFound/NotFound'
import theme from 'theme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Copyright from 'components/Copyright/Copyright'
import Grid from '@mui/material/Grid'
import { Loading } from 'components/Loading/Loading'
import HDDProducts from 'components/HDDetails/HDDProducts'
import EditableHDDProducts from 'components/HDDetails/EditableHDDProducts'

type Mode = 'edition' | 'read'

export default function FamilyHDDetails() {
  const [searchParams] = useSearchParams()
  const [details, setDetails] = useState<HourDeclarationDetail[]>([])
  const [mission, setMission] = useState<any>()
  const [allValidated, setAllValidated] = useState<boolean>(false)
  const [mode, setMode] = useState<Mode>('read')
  const missionId = searchParams.get('missionId')
  const date = searchParams.get('date')

  useEffect(() => {
    if (!missionId || !date) {
      return
    }

    getHDs(missionId, date).then(
      ({ mission, detailsWithPrices, allValidated }) => {
        setMission(mission)
        setDetails(
          [...detailsWithPrices].sort(
            (a, b) =>
              new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          )
        )
        setAllValidated(allValidated)
      }
    )
  }, [date, missionId])

  const isProductDeclaration = details.every(detail => detail.type)

  if (!date || !missionId) {
    return <NotFound />
  }

  if (!mission) {
    return <Loading />
  }

  if (details.length === 0) {
    return <NotFound />
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: currentTheme =>
              currentTheme.palette.mode === 'light'
                ? currentTheme.palette.grey[100]
                : currentTheme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            paddingTop: '66px'
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <Grid container spacing={3} paddingBottom={8}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <img
                  src="/img/logo-click-and-care-color.png"
                  style={{
                    display: 'inline-block',
                    width: '350px',
                    textAlign: 'center'
                  }}
                  alt="logo"
                />
                <br />
                <br />
                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}
                >
                  {isProductDeclaration ? (
                    mode === 'read' ? (
                      <HDDProducts
                        encryptedMissionId={missionId}
                        mission={mission}
                        detailsWithPrices={details}
                        allValidated={allValidated}
                        date={date}
                        onContest={() => setMode('edition')}
                      />
                    ) : (
                      <EditableHDDProducts
                        encryptedMissionId={missionId}
                        mission={mission}
                        detailsWithPrices={details}
                        allValidated={allValidated}
                        date={date}
                      />
                    )
                  ) : (
                    <LegacyFamilyHDDetails
                      encryptedMissionId={missionId}
                      mission={mission}
                      detailsWithPrices={details}
                      allValidated={allValidated}
                      date={date}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
