import { List, ListItem, Paper } from '@mui/material'
import { DateTime } from 'luxon'
import { usePDF } from 'react-to-pdf'
import theme from '../../../theme'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  HourDeclarationDetail,
  postValidation
} from 'adapters/backend/hourDeclaration'
import { capitalize } from '../../../utils/tools'
import { GradientButton } from 'components/Button/Button.style'
import { getProductLabel, Pricing } from 'utils/labels'
import { HDDetailsFooter } from 'components/HDDetails/HDDetailsFooter'
import { Mission } from 'types/entity'

const makeEmailBody = (pricings: Pricing[]) => {
  let html = `Bonjour,%0DVous contestez le rapport de votre intervenant.%0DNous vous invitons à détailler sous ce texte les jours, nature des intervention et quantités concernés par la modification.%0D%0D`

  for (const pricing of pricings) {
    html =
      html +
      `${capitalize(
        DateTime.fromISO(pricing.slotStartDate)
          .setLocale('fr')
          .toFormat('dd/MM')
      )} : ${getProductLabel(
        pricing.product,
        pricing.surchargeType,
        pricing.nightType
      )} : ${
        pricing.productType === 'HORAIRE'
          ? `${pricing.hoursBilled.toFixed(2)}`
          : `1`
      } %0D`
  }

  html =
    html +
    `%0DDès réception, nous apporterons les modifications et vous reverrons la facture correspondante. Le prélèvement aura lieu 24h après réception de la facture.%0DCordialement,%0DVotre équipe support%0D--------------------------%0DVos modifications :`

  return html
}

type Props = {
  encryptedMissionId: string
  mission: Mission
  detailsWithPrices: HourDeclarationDetail[]
  allValidated: boolean
  date: string
}

export default function LegacyFamilyHDDetails({
  encryptedMissionId,
  mission,
  detailsWithPrices,
  allValidated,
  date
}: Props) {
  const pricings = detailsWithPrices.reduce<Pricing[]>(
    (acc, detail) => acc.concat(detail.prices),
    []
  )

  const onValidate = () =>
    postValidation(encryptedMissionId, date).then(() => {
      window.location.href = `/confirmer-famille?missionId=${encryptedMissionId}&date=${date}`
    })

  const { toPDF, targetRef } = usePDF({ filename: 'declaration.pdf' })

  return (
    <>
      <Box paddingBottom={2} display={'flex'} justifyContent={'flex-end'}>
        <GradientButton onClick={() => toPDF()}>
          Télécharger le PDF
        </GradientButton>
      </Box>
      <Paper
        ref={targetRef}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {pricings.length === 0 && (
          <Box>Aucune déclaration pour le mois en cours</Box>
        )}
        {mission && (
          <Box fontSize={'1.3em'} textAlign={'center'} width="100%">
            <Box
              textAlign={'center'}
              color={theme.palette.primary.main}
              fontWeight="500"
            >
              DÉCLARATION HORAIRE{' '}
              {mission.contractType === 'liberal' ? 'LIBÉRAL' : 'CDI'} DU MOIS
              DE{' '}
              {DateTime.fromISO(pricings[0].slotStartDate)
                .setLocale('fr')
                .toFormat('MMMM yyyy')
                .toUpperCase()}
            </Box>
            <Box>
              déposée par {mission.careGiver.person.firstName}{' '}
              {mission.careGiver.person.lastName}
            </Box>
            <br />
          </Box>
        )}
        <List sx={{ width: '100%' }}>
          <ListItem
            key={'labels'}
            sx={{
              width: '100%',
              paddingLeft: 0,
              borderBottom: '1px solid lightgrey'
            }}
          >
            <Box display={'flex'} width={'100%'} flexDirection={'row'}>
              <Box flex={0.2} textAlign="center">
                <b>Date</b>
              </Box>
              <Box flex={0.6} textAlign="center">
                <b>Nature des interventions</b>
              </Box>
              <Box flex={0.2} textAlign="center">
                <b>Quantité</b>
              </Box>
            </Box>
          </ListItem>
          {pricings.map(pricing => (
            <ListItem
              key={JSON.stringify(pricing)}
              sx={{
                width: '100%',
                paddingLeft: 0,
                borderBottom: '1px solid lightgrey'
              }}
            >
              <Box display={'flex'} width={'100%'} flexDirection={'row'}>
                <Box
                  flex={0.2}
                  textAlign="center"
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Box flex={0.5}>
                    {capitalize(
                      DateTime.fromISO(pricing.slotStartDate)
                        .setLocale('fr')
                        .toFormat('EEEE')
                    )}
                  </Box>
                  <Box flex={0.5}>
                    {capitalize(
                      DateTime.fromISO(pricing.slotStartDate)
                        .setLocale('fr')
                        .toFormat('dd/MM')
                    )}
                  </Box>
                </Box>
                <Box flex={0.6} textAlign="center">
                  {getProductLabel(
                    pricing.product,
                    pricing.surchargeType,
                    pricing.nightType
                  )}
                </Box>
                <Box flex={0.2} textAlign="center">
                  {pricing.productType === 'HORAIRE'
                    ? `${pricing.hoursBilled.toFixed(2)}`
                    : `1`}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
        <br />
        <HDDetailsFooter mission={mission} pricings={pricings} />
      </Paper>
      {(pricings.length && (
        <>
          <Box
            paddingTop={2}
            display={'flex'}
            justifyContent={'flex-end'}
            width={'100%'}
            gap={2}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              href={`mailto:facturation@clickandcare.fr?subject=Contestation Déclarations horaire&body=${makeEmailBody(
                pricings
              )}`}
              style={{ color: 'white', borderRadius: '8px' }}
              disabled={allValidated}
            >
              CONTESTER LA DÉCLARATION
            </Button>
            <GradientButton
              onClick={() => onValidate()}
              disabled={allValidated}
            >
              VALIDER LA DÉCLARATION
            </GradientButton>
          </Box>
          {allValidated && (
            <Box
              paddingTop={2}
              display={'flex'}
              justifyContent={'flex-end'}
              width={'100%'}
              gap={2}
            >
              Vous avez déjà validé le mois en cours
            </Box>
          )}
        </>
      )) || <></>}
    </>
  )
}
